.theme-color-dark-blue {
    background-color: #222a45;
    z-index: 1;
}

.add-icon {
    color: white;
}

.cancel-button {
    color: #222a45
}

.trix-editor {
    cursor: text;
    height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

.choose-account-scroller {
    overflow-y: auto;
    max-height: 300px;
}

.choose-account-scroller::-webkit-scrollbar {
    width: 0.5em;
    background-color: #f5f5f5;
}

.choose-account-scroller::-webkit-scrollbar-thumb {
    background-color: rgb(196, 191, 191);
}

.choose-account-scroller::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.trix-editor::-webkit-scrollbar {
    width: 0.8em;
    background-color: #f5f5f5;
}

.trix-editor::-webkit-scrollbar-thumb {
    background-color: rgb(196, 191, 191);
}

.trix-editor::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.switch span{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}